/**
 * @name: index
 * @author: Gzm
 * @date: 2023-06-01 10:06
 * @description：index
 * @update: 2023-06-01 10:06
 */
import {get, postJ} from "@/request";
import {IPageRes} from "@/apis/page";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";

/**
 * 教练佣金结算分页查询
 */
export const queryCoachSettlementByPageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/coachSettlement/queryCoachSettlementByPage", param)

/**
 * 审核
 */
export const coachSettlementAuditApi = (param:any)=> postJ("/golf/coachSettlement/audit", param)

/**
 * 批量发放上月佣金
 */
export const coachSettlementBatchGrantApi = (ids:string)=> postJ(`/golf/coachSettlement/batchGrant?ids=${ids}`)

/**
 * 教练上月佣金分页查询
 */
export const queryLastMonthCommissionByPageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/coachSettlement/queryLastMonthCommissionByPage", param)

/**
 * 教练上月佣金分页查询视频
 */
export const lastMonthVideoCoursesByPageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/coachSettlement/lastMonthVideoCoursesByPage", param)


/**
 * 教练佣金分页查询
 */
export const queryCoachCommissionByPagePageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/coachSettlement/queryCoachCommissionByPage", param)

/**
 * 教练佣金分页查询视频
 */
export const videoCoursesApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("golf/coachSettlement/videoCourses", param)


/**
 * 渠道方佣金结算分页查询
 */
export const queryChannelSettlementByPageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/channelSettlement/queryChannelSettlementByPage", param)

/**
 * 渠道佣金分页查询
 */
export const queryChannelCommissionByPageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/channelSettlement/queryChannelCommissionByPage", param)

/**
 * 渠道佣金结算导出Excel
 * @param params
 */
export const channelSettlementExportApi = (ids:string) => get(`/golf/channelSettlement/channelSettlementExport?ids=${ids}`, {}, "blob")

/**
 * 达人佣金结算分页查询
 */
export const queryTalentSettlementByPagePageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/talentSettlement/queryTalentSettlementByPage", param)

/**
 * 达人佣金审核
 */
export const talentSettlementAuditApi = (param:any)=> postJ("/golf/talentSettlement/audit", param)

/**
 * 达人上月佣金分页查询
 */
export const talentQueryLastMonthCommissionByPageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/talentSettlement/queryLastMonthCommissionByPage", param)

/**
 * 达人上月佣金分页查询
 */
export const queryTalentCommissionByPageApi = (param:ICoachByPage)=> get<IPageRes<ICoachByPage[]>>("/golf/talentSettlement/queryTalentCommissionByPage", param)

/**
 * 达人批量发放上月佣金
 */
export const talentSettlementBatchGrantApi = (ids:string)=> postJ(`/golf/talentSettlement/batchGrant?ids=${ids}`)
