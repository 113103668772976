
/**
 * @name: coachReconcileLook
 * @author: Gzm
 * @date: 2023-06-01 14:14
 * @description：财务结算-教练佣金对账-查看佣金
 * @update: 2023-06-01 14:14
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import {
  queryCoachCommissionByPagePageApi,
  queryLastMonthCommissionByPageApi,
  videoCoursesApi
} from "@/apis/finance/coachReconcile";
import {deepCopy} from "@/utils/common";
import Qrcode from "qrcodejs2";

@Component({})
export default class ProductBasic extends Vue {
  @Prop(Boolean) dialogVisible!: boolean;
  @Prop(Object) data!: object;
  dialogCode: boolean = false
  queryForm: any = {
    page: 1,
    limit: 10
  }
  queryFormVideo: any = {
    page: 1,
    limit: 10
  }
  total: number = 0
  totalVideo: number = 0
  tableData: ICoachByPage[] = []
  tableDataVideo: ICoachByPage[] = []
  activeName = 'first'

  ultimoData: any = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        width: 200,
      },
      {
        label: "课程",
        prop: "courseName",
        align: 'center',
      },
      {
        label: "券码",
        prop: "ticketCode",
        align: 'center',
      },
      {
        label: "卡券单价",
        prop: "price",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "orderCustom",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "userName",
        align: 'center',
        search: true,
        hide: true
      },
      {
        label: "佣金",
        prop: "commission",
        align: 'center',
      },
      {
        label: "使用时间",
        prop: "writeOffTime",
        align: 'center',
      },
      {
        label: "发放状态",
        prop: "commissionStatus",
        align: 'center',
        value: 2,
        type: "switch",
        dicData: [{label: '未发放', value: 1}, {label: '已发放', value: 2}],
      },
      {
        "label": "使用时间",
        "prop": "payTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
        hide: true
      },
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        search: true,
        hide: true
      },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true
      },
    ]
  }

  crudOptionVideo: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        width: 200,
      },
      {
        label: "视频课程名称",
        prop: "courseName",
        align: 'center',
      },
      {
        label: "视频课程单价",
        prop: "price",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "orderCustom",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "userName",
        align: 'center',
        search: true,
        hide: true
      },
      {
        label: "佣金",
        prop: "commission",
        align: 'center',
      },
      {
        label: "发放状态",
        prop: "commissionStatus",
        align: 'center',
        value: 2,
        type: "switch",
        dicData: [{label: '未发放', value: 1}, {label: '已发放', value: 2}],
      },
      {
        label: "订单编号",
        prop: "orderSn",
        align: 'center',
        search: true,
        hide: true
      },
    ]
  }

  dialogCodeData: any = {}

  /**
   * 获取数据
   */
  getList() {
    const query: any = deepCopy(this.queryForm)
    if (this.queryForm.payTime && this.queryForm.payTime.length === 2) {
      query.payTimeStart = this.queryForm.payTime[0]
      query.payTimeEnd = this.queryForm.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime
    queryCoachCommissionByPagePageApi(query).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  getListVideo() {
    const query: any = deepCopy(this.queryFormVideo)
    if (this.queryFormVideo.payTime && this.queryFormVideo.payTime.length === 2) {
      query.payTimeStart = this.queryFormVideo.payTime[0]
      query.payTimeEnd = this.queryFormVideo.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime
    videoCoursesApi(query).then(e => {
      this.tableDataVideo = e.list;
      this.totalVideo = e.total
    })
  }


  get computedData() {
    return this.data;
  }

  @Watch('computedData')
  onChangeValue(newVal: any, oldVal: any) {
    this.queryForm.id = newVal.id
    this.queryFormVideo.id = newVal.id
    this.activeName = "first"
    this.getList()
  }

  handleCancel(val: boolean) {
    this.$emit('Change', val)
  }

  handleOpen(row: any) {
    this.dialogCode = true
    this.dialogCodeData = row
    setTimeout(() => {
      this.$nextTick(() => {
        const dom = document.querySelector(`#qrcode`)
        dom!.innerHTML = '';
        new Qrcode(dom, {
          text: row.ticketCode,
          width: 200,
          height: 200
        })
      })
    }, 0)
  }

  handleClick(e: any) {
    console.log(e)
    if (e.index == 0) {
      this.getList()
    } else {
      this.getListVideo()
    }
  }
}
